<div *ngIf="!isLoading">
  <div *ngIf="(migrationObject === null || migrationObject === undefined) && !partnerIsAlreadySetUp">
    <div size-xs="12" size-md="4" class="mobile-center" style="flex-wrap: wrap; justify-content: center; padding-top: 10%">
      <ion-card class="cards ion-text-center" style="width: 900px">
        <ion-card-title style="text-align: center">No migration detected</ion-card-title>
        <ion-card-header style="font-size: 16px">
          We have not registered any move of
          <strong>{{ partner.id }}</strong> to be under Cloud Factory.
          <br />
          <br />
          If you should be in the process of being moved, please contact Cloud Factory.
          <br />
          <br />
          Phone: <strong>+45 53 50 20 20</strong>
          <br />
          Email:
          <strong><a href="mail@cloudfactory.dk?subject = Regarding Exclaimer Migration">mail&#64;cloudfactory.dk</a></strong>
          <br />
          <br />

          <ion-button class="ion-button" color="primary" (click)="goToPortal()">Go to Partner Portal</ion-button>
        </ion-card-header>
      </ion-card>
    </div>
  </div>

  <div *ngIf="partnerIsAlreadySetUp">
    <div size-xs="12" size-md="4" class="mobile-center" style="flex-wrap: wrap; justify-content: center; padding-top: 10%">
      <ion-card class="cards ion-text-center" style="width: 900px">
        <ion-card-title style="text-align: center">No migration detected</ion-card-title>
        <ion-card-header style="font-size: 16px"> This partner is already set up with Exclaimer </ion-card-header>
        <ion-button class="ion-button" color="primary" (click)="goToPortal()">Go to Partner Portal</ion-button>
      </ion-card>
    </div>
  </div>

  <div *ngIf="migrationObject?.migrationStatus === 'User Pending'">
    <div size-xs="12" size-md="4" class="mobile-center" style="flex-wrap: wrap; justify-content: center; padding-top: 10%">
      <ion-card class="cards ion-text-center" style="width: 900px">
        <ion-card-title style="text-align: center">Confirm migrating your Exclaimer account to Cloud Factory </ion-card-title>

        <ion-card-header style="font-size: 16px" class="ion-margin-top">
          Please confirm the migration of
          <strong>{{ migrationObject?.resellerEmail }}</strong> to Cloud Factory.
          <br />
          <br />
          <br />
          If you wish to continue to migrate your account, please click the button below.
          <br />
          <br />
          <ion-button class="ion-button" (click)="confirmMove()">
            <ion-span *ngIf="!isConfirming">Confirm</ion-span>
            <ion-span *ngIf="isConfirming">
              <ion-spinner name="crescent"></ion-spinner>
            </ion-span>
          </ion-button>
        </ion-card-header>
      </ion-card>
    </div>
  </div>

  <div *ngIf="migrationObject?.migrationStatus === 'Exclaimer Pending'">
    <div size-xs="12" size-md="4" class="mobile-center" style="flex-wrap: wrap; justify-content: center; padding-top: 10%">
      <ion-card class="cards ion-text-center" style="width: 900px">
        <ion-card-title style="text-align: center">Migrations is pending</ion-card-title>
        <ion-card-header style="font-size: 16px">
          We have registered the migration request of
          <strong>{{ migrationObject?.resellerEmail }}</strong> to be under Cloud Factory.
          <br />
          <br />
          The process is currently being handled by Exclaimer and will soon be completed.
          <br />
          <br />
          When the process is completed, you will be able to see it under your partner profile on our Partner Portal and you will also receive an email notification.
          <br />
          <br />

          <ion-button class="ion-button" color="primary" (click)="goToPortal()">Go to Partner Portal</ion-button>
        </ion-card-header>
      </ion-card>
    </div>
  </div>

  <div *ngIf="migrationObject?.migrationStatus === 'Migrated'">
    <div size-xs="12" size-md="4" class="mobile-center" style="flex-wrap: wrap; justify-content: center; padding-top: 10%">
      <ion-card class="cards ion-text-center" style="width: 900px">
        <ion-card-title style="text-align: center">Migration is complete</ion-card-title>
        <ion-card-header style="font-size: 16px">
          The migration of
          <strong>{{ migrationObject?.resellerEmail }}</strong> is complete.
          <br />
          <br />
          You are now able to manage Exclaimer from our Partner Portal.
          <br />
          <br />
          If you have any questions, please contact our support:
          <br />
          <br />
          Phone: <strong>+45 53 50 20 20</strong>
          <br />
          Email:
          <strong><a href="mail@cloudfactory.dk?subject = Regarding Exclaimer Migration">mail&#64;cloudfactory.dk</a></strong>

          <br />
          <br />

          <ion-button class="ion-button" color="primary" (click)="goToPortal()">Go to Partner Portal</ion-button>
        </ion-card-header>
      </ion-card>
    </div>
  </div>
</div>

<ion-row *ngIf="isLoading">
  <ion-col size="12" class="ion-flex ion-justify-content-center">
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>
