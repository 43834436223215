import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExclaimerMigrationObject } from '../../models/exclaimerMigrationObject';
import { IPartner } from '../../../partner/models/partner.model';
import { Select, Store } from '@ngxs/store';
import { GetPartnersSelf } from '../../../partner/state/partner.actions';
import { PartnerState } from '../../../partner/state/partner.state';
import { Subscription } from 'rxjs';
import { ExclaimerPartnerService } from '../../services/exclaimer.partner.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-exclaimer-migration-page',
  templateUrl: './exclaimer-migration-page.component.html',
  styleUrls: ['./exclaimer-migration-page.component.scss'],
})
export class ExclaimerMigrationPageComponent implements OnInit, OnDestroy {
  @Select(PartnerState.getAuthenticatedPartner) partner$;

  partner: IPartner;
  isLoading = true;
  isConfirming = false;
  migrationObject: ExclaimerMigrationObject = undefined;

  teardown: Subscription = new Subscription();
  partnerIsAlreadySetUp = false;

  constructor(
    private store: Store,
    private exclaimerPartnerService: ExclaimerPartnerService,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new GetPartnersSelf());

    const sub = this.partner$.subscribe((partnerSelf) => {
      this.partner = partnerSelf;
    });
    this.teardown.add(sub);

    this.setMigrationData();
  }

  ngOnDestroy(): void {
    this.teardown.unsubscribe();
  }

  confirmMove() {
    this.isConfirming = true;

    const confirmSub = this.exclaimerPartnerService
      .confirmMigrationToCloudfactory(this.partner.id)
      .pipe(
        tap({
          next: (response) => {
            this.isConfirming = false;
            this.migrationObject.migrationStatus = 'Exclaimer Pending';
            this.toastr.success('Confirmation received. Your migration will now be handled by Exclaimer and you will receive an email when it is done', 'Confirm migration');
          },
          error: (error) => {
            this.toastr.error('A error occured with you confirmation' + error, 'Confirm migration error');
            this.isConfirming = false;
          },
        }),
      )
      .subscribe();

    this.teardown.add(confirmSub);
  }

  goToPortal() {
    const baseUrl = window.location.origin;
    window.location.href = baseUrl + '/home';
  }

  private setMigrationData() {
    const partnerSub = this.partner$.subscribe((response) => {
      if (response === null) return;

      this.partner = response;

      const migrationSub = this.exclaimerPartnerService.getPartnerMigrationDetails(response.id).subscribe({
        next: (response) => {
          this.migrationObject = response;
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.partnerIsAlreadySetUp = true;
          this.isLoading = false;
        },
      });

      this.teardown.add(migrationSub);
    });
    this.teardown.add(partnerSub);
  }
}
